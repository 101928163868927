import tools from '@/tools/index.js'

// let timeArr = []
let cacheSize = parseInt(localStorage.getItem('pageSize'))
const serialnumberData = {
    serialnumberTableSet: {
        tableName:'table_serialnumber',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        orderby: 'order by Delivery_number desc, Delivery_item_number, CAST(Quantity AS INTEGER)',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Delivery_number', label: '发货通知单号', align: 'left', field: 'Delivery_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Delivery_item_number', label: '发货通知单行号', align: 'right', field: 'Delivery_item_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Serial_number', label: '序列号', align: 'left', field: 'Serial_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Quantity', label: '序号', align: 'right', field: 'Quantity', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true, required: false, name: 'Customer_NO', label: '客户编号', align: 'left', field: 'Customer_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Customer_PO_number', label: '客户订单号', align: 'left', field: 'Customer_PO_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Customer_PO_item_number', label: '客户订单行号', align: 'right', field: 'Customer_PO_item_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Sales_order_number', label: '西门子销售订单号', align: 'left', field: 'Sales_order_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Sales_order_item_number', label: '西门子销售订单行号', align: 'right', field: 'Sales_order_item_number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material', label: '物料编号', align: 'left', field: 'Material', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SN_Create_Date', label: 'SN创建日期', align: 'left', field: 'SN_Create_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SN_Create_Time', label: 'SN创建时间', align: 'left', field: 'SN_Create_Time', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },

}
const packinglistData = {
    packinglistTableSet: {
        tableName:'table_packinglist',
        loading: false,
        packing: true,
        rowkey: 'id',
        showSelection:true,
        selection: 'multiple',
        // separator: 'cell',
        orderby: 'order by DELIVERY_NO desc,DELIVERY_ITEM_NO',
        selected: [],
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [],
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: '序号', align: 'left', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Customer_Material', label: '客户物料编号', align: 'left', field: 'Customer_Material', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'M_Item', label: '客户订单行号', align: 'left', field: 'M_Item', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_SO_NO', label: '西门子销售订单号', align: 'left', field: 'F_SO_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SO_ITEM_NO', label: '西门子销售订单行号', align: 'right', field: 'SO_ITEM_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_NO', label: '发货通知单号', align: 'left', field: 'DELIVERY_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DELIVERY_ITEM_NO', label: '发货通知单行号', align: 'right', field: 'DELIVERY_ITEM_NO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Consignment_Number', label: '发货通知单号（完整）', align: 'left', field: 'Consignment_Number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'HU_Item_Number', label: '包装数量（箱号）', align: 'right', field: 'HU_Item_Number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Options', label: '选件', align: 'left', field: 'Options', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'HU_Item_Quantity', label: '数量', align: 'right', field: 'HU_Item_Quantity', sortable: true,
                format: (val) => `${val ? val : ''}`
            },



            // {
            //     check: true, required: false, name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },


            {
                check: true, required: false, name: 'Storage_Location', label: '货物存储地代码', align: 'left', field: 'Storage_Location', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Description_of_SL', label: '货物存储地描述', align: 'left', field: 'Description_of_SL', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Shipping_Point', label: '发货点代码', align: 'left', field: 'Shipping_Point', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Created_Date', label: '创建日期（DN）', align: 'left', field: 'Created_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Posting_Date', label: '发货日期（PGI）', align: 'left', field: 'Posting_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },


            // {
            //     check: true, required: false, name: 'Handling_Unit', label: '包装编号', align: 'left', field: 'Handling_Unit', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Material', label: '西门子物料号', align: 'left', field: 'Material', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Description_of_Product', label: '产品描述（SO）', align: 'left', field: 'Description_of_Product', sortable: true,
                format: (val) => `${val ? val : ''}`
            },


            {
                check: true, required: false, name: 'MFN', label: 'MFN号', align: 'left', field: 'MFN', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Base_Unit', label: '单位', align: 'left', field: 'Base_Unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Total_Weight', label: '毛重', align: 'right', field: 'Total_Weight', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Weight_Unit', label: '重量单位', align: 'left', field: 'Weight_Unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Length', label: '包装尺寸（长）', align: 'right', field: 'Length', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Width', label: '包装尺寸（宽）', align: 'right', field: 'Width', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Height', label: '包装尺寸（高）', align: 'right', field: 'Height', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Unit_of_Dimension', label: '长宽高尺寸单位', align: 'left', field: 'Unit_of_Dimension', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Customer_Order', label: '客户订单编号', align: 'left', field: 'Customer_Order', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'AL_Code', label: '出口控制代码（欧洲）', align: 'left', field: 'AL_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ECCN_Code', label: '出口控制代码（美国）', align: 'left', field: 'ECCN_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ShipTo_Name', label: '收货客户名', align: 'left', field: 'ShipTo_Name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ShipTo_Street', label: '收货地址', align: 'left', field: 'ShipTo_Street', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ShipTo_City', label: '收货城市', align: 'left', field: 'ShipTo_City', sortable: true,
                format: (val) => `${val ? val : ''}`
            },


            {
                check: true, required: false, name: 'Created_Date', label: '创建日期（DN）', align: 'left', field: 'Created_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },

}
const outstandingaccountData = {
    outstandingaccountTableSet: {
        tableName:'table_outstandingccount',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        orderby: 'order by Is_Due_Date desc,Net_Due_Date,BELNR desc',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        data: [
            { ItemNo: '0100' },
            { ItemNo: '0200' },
        ],
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'right', field: 'id', sortable: true, style: 'width:50px',
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Is_Due_Date', label: '是否Overdue', align: 'center', field: 'Is_Due_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'U_ZUONR', label: '西门子销售订单号', align: 'right', field: 'U_ZUONR', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Customer_PO_Number', label: '客户合同号', align: 'center', field: 'Customer_PO_Number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DMSHB', label: '金额', align: 'right', field: 'DMSHB', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'HWAER', label: '币种', align: 'left', field: 'HWAER', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BELNR', label: '凭证号码', align: 'left', field: 'BELNR', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BLART', label: '凭证类型', align: 'center', field: 'BLART', sortable: true,
                icon:'fas fa-info-circle',
                icontip:'RV->西门子开票\nDA/DZ->客户付款\nAB->清账余额',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'General_Ledger_Account', label: '总账科目', align: 'left', field: 'General_Ledger_Account', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Net_Due_Date', label: '到期日', align: 'left', field: 'Net_Due_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BLDAT', label: '凭证日期', align: 'left', field: 'BLDAT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'XBLNR', label: 'VAT 发票号（参考号）', align: 'left', field: 'XBLNR', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'PRCTR', label: '利润中心', align: 'left', field: 'PRCTR', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZTERM', label: '付款方式', align: 'center', field: 'ZTERM', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Cleared_OpenItem', label: 'CLEARED标志', align: 'center', field: 'Cleared_OpenItem', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Company_Code', label: '公司代码', align: 'center', field: 'Company_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SGTXT', label: '文本', align: 'left', field: 'SGTXT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Posting_Date', label: '记账日期/发货日期', align: 'center', field: 'Posting_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Clearing_Date', label: '清账日期', align: 'center', field: 'Clearing_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },


        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },
    BLART__options:[],
}
const largeorderdiscountsData = {
    largeorderdiscountsTableSet: {
        tableName:'table_largeorderdiscounts',
        loading: false,
        rowkey: 'id',
        selection: 'single',
        selected: [],
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'right', field: 'id', sortable: true,
            //     format: (val) => `${val ? val : ''}`
            // },
            {
                check: true, required: false, name: 'Customer_No', label: '客户编号', align: 'left', field: 'Customer_No', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_SlsOrg', label: '销售组织', align: 'left', field: 'F_SlsOrg', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_DistCh', label: '销售渠道', align: 'left', field: 'F_DistCh', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_CndTyp', label: '折扣类型', align: 'center', field: 'F_CndTyp', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_CondT', label: '条件类型', align: 'center', field: 'F_CondT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_group1', label: '物料组1', align: 'left', field: 'Material_group1', sortable: true,
                format: (val) => `${val == 'None' ? '' : val}`
            },
            {
                check: true, required: false, name: 'MPG', label: '价格组（中国）', align: 'left', field: 'MPG', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_StartDt', label: '有效期开始时间', align: 'left', field: 'F_StartDt', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_EndDt', label: '有效期结束时间', align: 'left', field: 'F_EndDt', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Rate', label: '折扣', align: 'left', field: 'F_Rate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_RtUnit', label: '折扣单位', align: 'left', field: 'F_RtUnit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_SclInd', label: '价格设置分类', align: 'left', field: 'F_SclInd', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_ScaleValue', label: '分级价格（折扣依据）', align: 'right', field: 'F_ScaleValue', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'Scale_Currency', label: '分级价格的币种', align: 'left', field: 'Scale_Currency', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Scale_Value_Level', label: '分级价格的等级', align: 'left', field: 'Scale_Value_Level', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'created_at', label: '更新时间', align: 'left', field: 'created_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        data: [

        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },

}
const sprData = {
    SprSource: {
        tableName:'table_SprSource',
        rowkey: 'id',
        // separator: 'cell',
        selection: 'single',
        selected: [],
        spr: true,
        orderby: 'order by F_Customer_Quotation desc,No',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [

            {
                check: true, required: false, name: 'is_used', label: '是否可用', align: 'left', field: 'is_used', sortable: true,
                format: (val) => `${val === 1 ? '不可用' : '可用'}`
            },
            {
                check: true, required: false, name: 'F_Customer_Quotation', label: '报价单号', align: 'left', field: 'F_Customer_Quotation', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'F_Approved_SPR_Number', label: '特价单号', align: 'left', field: 'F_Approved_SPR_Number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Issue_Date', label: '特价单生成日期', align: 'left', field: 'Issue_Date', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'Valid_From', label: '有效开始日期', align: 'left', field: 'Valid_From', sortable: true,
                format: (val) => `${val ? val : ''}`
                // format: (val) => date.formatDate(val,'YYYY/MM/DD')
            },
            {
                check: true, required: false, name: 'Valid_TO', label: '有效结束日期', align: 'left', field: 'Valid_TO', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'End_user_No', label: '最终客户代码', align: 'left', field: 'End_user_No', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'End_user_Name', label: '最终客户中文名称', align: 'left', field: 'End_user_Name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'No', label: '特价单行号', align: 'right', field: 'No', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_Number', label: '西门子物料编号', align: 'left', field: 'Material_Number', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Qty', label: '数量', align: 'right', field: 'Qty', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Final_Discount_off', label: '折扣（‰）', align: 'right', field: 'Final_Discount_off', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate', label: '折扣价格（总价）', align: 'right', field: 'ZDIS_Rate', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'Unit_Price_after_Discount', label: '折后单价', align: 'right', field: 'Unit_Price_after_Discount', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'Condition_Value', label: '净价（总价）', align: 'right', field: 'Condition_Value', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'ZLP2_Price', label: '表价（单价）', align: 'right', field: 'ZLP2_Price', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'ZLP2_Price_Total', label: '表价（总价）', align: 'right', field: 'ZLP2_Price_Total', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'ZPN0_Price', label: '固定价格（单价）', align: 'right', field: 'ZPN0_Price', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'ZPN0_Price_Total', label: '固定价格（总价）', align: 'right', field: 'ZPN0_Price_Total', sortable: true,
                format: (val) => `${val ? val == 'None' ? '' : tools.formattedPrice(val) : ''}`
            },

            {
                check: true, required: false, name: 'SPR_Category', label: '特价类型', align: 'center', field: 'SPR_Category', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SalesEmployee_Name', label: '申请人', align: 'left', field: 'SalesEmployee_Name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Ship_To_Party', label: '收货点代码', align: 'left', field: 'Ship_To_Party', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SPR_Ship_To_City', label: '收货点城市', align: 'left', field: 'SPR_Ship_To_City', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'SPR_Ship_To_Address', label: '收货点地址（中文）', align: 'left', field: 'SPR_Ship_To_Address', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Payment_of_Quotation', label: '付款方式代码', align: 'left', field: 'Payment_of_Quotation', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Net_Value_of_Quotation', label: '报价单总净价', align: 'right', field: 'Net_Value_of_Quotation', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },


            {
                check: true, required: false, name: 'Sales_Unit', label: '销售单位', align: 'left', field: 'Sales_Unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Currency', label: '币别', align: 'left', field: 'Currency', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Customer_No', label: '客户编号', align: 'left', field: 'Customer_No', sortable: true,
                format: (val) => `${val ? val : ''}`
            },


        ],
        data: [],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },

            ]
        },
        height: ''
    },
}
const bomData = {
    BomSource: {
        tableName:'bomData.BomSource',
        loading: false,
        separator: 'cell',
        bomSource: true,
        rowkey: 'id',
        showSelection:true,
        selection: 'multiple',
        selected: [],
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            // {
            //     check: true, required: false, name: 'id', label: 'ID', align: 'right', field: 'id', sortable: true,
            //     // format: (val) => `${val ? val : ''}`
            //     format: (val) => `${val}`
            // },
            {
                check: true, required: false, name: 'BOMNo', label: 'BOM编号', align: 'left', field: 'BOMNo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'custom_add', label: '自定义BOM', align: 'center', field: 'custom_add', sortable: false,
                format: (val) => `${val == 1 ? '是' : '否'}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）（主物料）', align: 'left', field: 'MLFB_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Option', label: '选件（主物料）', align: 'left', field: 'Option', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Quantity', label: '数量（主物料）', align: 'right', field: 'Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Item', label: '序号（子物料）', align: 'left', field: 'Item', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Sort', label: '订货号（Sort）（子物料）', align: 'left', field: 'Component_Sort', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Option', label: '选件（子物料）', align: 'left', field: 'Component_Option', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Conponent_Quantity', label: '数量（子物料）', align: 'right', field: 'Conponent_Quantity', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BOMUsg', label: 'BOM类型', align: 'center', field: 'BOMUsg', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BOMSt', label: 'BOM状态', align: 'center', field: 'BOMSt', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'AltBOM', label: 'BOM组号', align: 'left', field: 'AltBOM', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material', label: '西门子物料编号（主物料)', align: 'left', field: 'Material', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Description', label: '物料描述（主物料)', align: 'left', field: 'Description', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Unit', label: '单位（主物料）', align: 'left', field: 'Unit', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Status', label: '物料状态（主物料）', align: 'center', field: 'Status', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Del_Flag', label: '物料删除标志（主物料）', align: 'left', field: 'Del_Flag', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Description', label: '物料描述（子物料）', align: 'left', field: 'Component_Description', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ICt', label: '分类（子物料）', align: 'left', field: 'ICt', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component', label: '西门子物料编号（子物料）', align: 'left', field: 'Component', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Unit', label: '单位（子物料）', align: 'left', field: 'Component_Unit', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ValidFrom', label: '开始时间', align: 'left', field: 'ValidFrom', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ValidTo', label: '结束时间', align: 'left', field: 'ValidTo', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Status', label: '物料状态（子物料）', align: 'center', field: 'Component_Status', sortable: false,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Del_Flag', label: '物料删除标志（子物料）', align: 'left', field: 'Component_Del_Flag', sortable: false,
                format: (val) => `${val ? val : ''}`
            },

        ],
        data: [{ id: '01' }],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                { index: 1, name: ['自定义BOM'], icon: 'fas fa-edit', check_lines: 0, on_click: [], sign: 'custom', },
                { index: 2, name: ['删除行'], icon: 'fas fa-trash', check_lines: 0, on_click: [], sign: 'delete', },
                {
                    sign: 'refresh',
                    index: 3,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },

            ]
        },
        height: ''
    },

    BomFrom: {
        form_class: 'base_form',
        body: [
            {
                index: 1,
                body_class: 'q-gutter-sm row items-start',
                items: [
                    // {
                    //     index: 1, label: '业务公司', showlabel: true, name: 'company', value: '', type: 'select', required: true, variant: {
                    //         origin: ['e2e', 'getCompanyData', 'update_base_data'], fields: ['F_CompanyName', 'F_CompanyCode'],
                    //         auto_assignment: true
                    //     }, minWidth: 160
                    // },
                    { index: 0, align: 'left', label: 'BOM编号', showlabel: true, name: 'BOMNo', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 1, align: 'left', label: '订货号', showlabel: true, name: 'MLFB_Sort', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 2, align: 'left', label: '西门子物料编号', showlabel: true, name: 'Material', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 3, align: 'right', label: '数量', showlabel: true, name: 'Quantity', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 4, align: 'left', label: '单位', showlabel: true, name: 'Unit', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 5, align: 'left', label: '选件', showlabel: true, name: 'Option', value: '', type: 'input', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 6, align: 'left', label: '开始时间', showlabel: true, name: 'ValidFrom', value: '', type: 'date', filled: false, outlined: true, disable: false, minWidth: 160 },
                    { index: 7, align: 'left', label: '结束时间', showlabel: true, name: 'ValidTo', value: '', type: 'date', filled: false, outlined: true, disable: false, minWidth: 160 },
                ]
            },
        ]

    },
    customTable: {
        tableName:'bomData.customTable',
        rowkey: 'fId',
        data: [{ fId: 1, colKey: 'Component_Sort', rowKey: '0' }],
        selection: 'multiple',
        edit: 'true',
        selected: [],
        // pagination: { rowsPerPage: 10, rowsNumber: 0, page: 1 },
        columns: [
            {
                check: true, required: false, name: 'Component_Sort', label: '订货号（Sort）', align: 'left', field: 'Component_Sort', sortable: true, style: 'width: 200px', edit: 'text',
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Conponent_Quantity', label: '数量', align: 'right', field: 'Conponent_Quantity', sortable: true, style: 'width: 100px', edit: 'text', class: 'rtl-input',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Unit', label: '单位', align: 'center', field: 'Component_Unit', sortable: true, style: 'width: 200px', edit: 'text',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Item', label: '西门子物料编号', align: 'left', field: 'Item', sortable: true, style: 'width: 200px', edit: 'text',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Component_Option', label: '选件', align: 'left', field: 'Component_Option', sortable: true, style: 'width: 200px', edit: 'text',
                format: (val) => `${val ? val : ''}`
            },


        ],
        tools: {
            show_toolbar: true,
            title: '子物料',
            btns: [
                { index: 2, name: ['新增一行'], icon: 'fas fa-plus', check_lines: 0, on_click: [], sign: 'add', },
                { index: 3, name: ['删除行'], icon: 'fas fa-trash', check_lines: 0, on_click: [], sign: 'delete', },
            ]
        }
    }
}
const MaterialData = {
    MaterialSource: {
        tableName:'table_MaterialSource',
        table_class: 'layout-table',
        loading: false,
        showSelection:true,
        selection: 'multiple',
        selected: [],
        rowkey: 'id',
        data: [],
        orderby: 'order by MLFB_Sort desc,Option',
        pagination: { rowsPerPage: cacheSize ? cacheSize : 30, rowsNumber: 0, page: 1 },
        columns: [
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Print', label: '订货号（Print）', align: 'left', field: 'MLFB_Print', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_Description_ZH', label: '短描述（中文）', align: 'left', field: 'Material_Description_ZH', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ProductListPrice', label: '列表价(不含税)', align: 'right', field: 'ProductListPrice', sortable: true,
                icon:'fas fa-info-circle',
                icontip:'如果物料主数据价格与西门子订单价格有差异，请及时联系西门子商务或销售同事确认',
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'UnitofMeasure', label: '列表价单位', align: 'left', field: 'UnitofMeasure', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Per', label: '列表价单位数量', align: 'right', field: 'Per', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Currency', label: '列表价币种', align: 'left', field: 'Currency', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'L2_Valid_From', label: '列表价开始时间', align: 'left', field: 'L2_Valid_From', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'L2_Valid_To', label: '列表价结束时间', align: 'left', field: 'L2_Valid_To', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate', label: '折扣（%）', align: 'left', field: 'ZDIS_Rate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate_Unit', label: '折扣单位', align: 'left', field: 'ZDIS_Rate_Unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BU', label: 'BU', align: 'left', field: 'BU', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'BU2', label: 'BU2', align: 'left', field: 'BU2', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_pricing_group', label: '价格组（中国）', align: 'left', field: 'Material_pricing_group', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZPN0_Price', label: '固定价格', align: 'left', field: 'ZPN0_Price', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZPN0_Price_Currency', label: '固定价格币种', align: 'left', field: 'ZPN0_Price_Currency', sortable: true,
                format: (val) => `${val ? val : ''}`
            },




            {
                check: true, required: false, name: 'ItemCategoryGroup', label: '产品类型（PTS/PTO）', align: 'center', field: 'ItemCategoryGroup', sortable: true,
                icon:'fas fa-info-circle',
                icontip:'BANC --> PTO;  \nBANS --> PTO;  \nNORM --> PTS;  \nLEIS --> 虚拟物料 (无实物);  \nZKIT --> 组合物料 (主物料)',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DeliveryTime', label: '预计备货时间（日历日)', align: 'left', field: 'DeliveryTime', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'GRT', label: '运输及收货时间（工作日)', align: 'left', field: 'GRT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Substitute', label: '替代产品', align: 'left', field: 'Substitute', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Substitute2', label: '替代产品2', align: 'left', field: 'Substitute2', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Min_Order_Qty', label: '最小起订量', align: 'left', field: 'Min_Order_Qty', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Delivery_unit', label: '倍数起订数量', align: 'right', field: 'Delivery_unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material', label: '西门子物料编号', align: 'left', field: 'Material', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MFN', label: 'MFN号', align: 'left', field: 'MFN', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ProfitCenter', label: '利润中心', align: 'left', field: 'ProfitCenter', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Grossweight', label: '毛重', align: 'right', field: 'Grossweight', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'WeightUOM', label: '重量单位', align: 'left', field: 'WeightUOM', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'NetWeight', label: '净重', align: 'right', field: 'NetWeight', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MaterialGroup', label: '物料组', align: 'left', field: 'MaterialGroup', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'VendorNumber', label: '供应商号码', align: 'left', field: 'VendorNumber', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Country', label: '原产地', align: 'left', field: 'Country', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'GBK', label: 'GCK', align: 'left', field: 'GBK', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'PCK', label: 'PCK', align: 'left', field: 'PCK', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Scan_Flag', label: '产品序列号扫描标记', align: 'left', field: 'Scan_Flag', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'PriceGroup', label: '价格组（总部）', align: 'left', field: 'PriceGroup', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Length', label: '包装尺寸（长）', align: 'right', field: 'Length', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Width', label: '包装尺寸（宽）', align: 'right', field: 'Width', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Height', label: '包装尺寸（高）', align: 'right', field: 'Height', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Unit_of_Dimension', label: '长宽高尺寸单位', align: 'left', field: 'Unit_of_Dimension', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Delta_Flag', label: '标识', align: 'left', field: 'Delta_Flag', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'I_Date', label: '新增标识', align: 'left', field: 'I_Date', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'IsUpdate', label: '更新标识', align: 'left', field: 'IsUpdate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Sales_unit', label: '销售单位', align: 'left', field: 'Sales_unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Y', label: '转换关系数值Y', align: 'left', field: 'Y', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'X', label: '转换关系数值X', align: 'left', field: 'X', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MaterialDescription', label: '短描述（英文）', align: 'left', field: 'MaterialDescription', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Base_unit', label: '基本单位', align: 'left', field: 'Base_unit', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'Material_group1', label: '物料组1', align: 'left', field: 'Material_group1', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_group2', label: '物料组2', align: 'left', field: 'Material_group2', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_group3', label: '物料组3', align: 'left', field: 'Material_group3', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_group4', label: '物料组4', align: 'left', field: 'Material_group4', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_group5', label: '物料组5', align: 'left', field: 'Material_group5', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_status', label: '物料状态', align: 'left', field: 'Material_status', sortable: true,
                icon:'fas fa-info-circle',
                icontip:'空:可销售\nLF:可销售(FDB Released)\nT1:系统冻结(Auto block by PMI)\nT2:手动冻结(Manual block)',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Plant', label: '公司组织', align: 'left', field: 'Plant', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'AL_Code', label: '出口控制代码（欧洲）', align: 'left', field: 'AL_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ECCN_Code', label: '出口控制代码（美国）', align: 'left', field: 'ECCN_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Commodity_Code', label: '海关商品编码', align: 'left', field: 'Commodity_Code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Deletion_Flag', label: '物料删除标记', align: 'left', field: 'Deletion_Flag', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Dangerous_Goods', label: '危险品标记', align: 'left', field: 'Dangerous_Goods', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'EAN_code', label: 'EAN代码', align: 'left', field: 'EAN_code', sortable: true,
                format: (val) => `${val ? val : ''}`
            },

            {
                check: true, required: false, name: 'ZDIS_Table_Name', label: '折扣来源表名', align: 'left', field: 'ZDIS_Table_Name', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            // {
            //     check: true, required: false, name: 'CHANGE_TYPE', label: '变更类型', align: 'center', field: 'CHANGE_TYPE', sortable: true,
            //     format: (val) => `${val ? val == 'None' ? '' : val : ''}`
            // },
            {
                check: true, required: false, name: 'erprewrite_status', label: 'ERP回写状态', align: 'center', field: 'erprewrite_status', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'erprewrite_msg', label: 'ERP回写信息', align: 'left', field: 'erprewrite_msg', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'created_at', label: '更新时间', align: 'left', field: 'created_at', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_Full_Description', label: '长描述（英文）', align: 'left', field: 'Material_Full_Description', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_Full_Description_ZH', label: '长描述（中文）', align: 'left', field: 'Material_Full_Description_ZH', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                {
                    sign: 'update',
                    index: 1,
                    name: ['手动更新ERP', ''],
                    icon: 'fas fa-file-download',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'export',
                    index: 1,
                    name: ['导出数据', ''],
                    icon: 'fas fa-file-export',
                    click: ['outbound', 'tools_set'],
                },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },
            ]
        },
        height: ''
    },
    MaterialInputData: {
        clean_reg: undefined
    }
}
let last_fId = 0
function fetch_fId(){
    ++last_fId
    return last_fId.toString() + getRandomString(6)
}
/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前获取位置
 */
function RandomIndex(min, max, i){
    let index = Math.floor(Math.random()*(max-min+1)+min),

        // numStart = _charStr.length - 10;
        numStart = "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789".length - 10;
    //如果字符串第一位是数字，则递归重新获取
    if(i==0&&index>=numStart){
        index = RandomIndex(min, max, i);
    }
    //返回最终索引值
    return index;
}
/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
function getRandomString(len){
    // let min = 0, max = _charStr.length-1, _str = '';
    let min = 0, max = "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789".length-1, _str = '';
    //判断是否指定长度，否则默认长度为15
    len = len || 15;
    //循环生成字符串
    for(var i = 0, index; i < len; i++){
        index = RandomIndex(min, max, i);
        // _str += _charStr[index];
        _str += "abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789"[index];
    }
    return _str;
}
const stockData = {
    StockSource: {
        tableName:'table_StockSource',
        loading: false,
        separator: 'cell',
        rowkey: 'fId',
        selected: [],
        selection: 'multiple',
        edit: true,
        // pagination: { rowsPerPage: 1, rowsNumber: 100, page: 1 },
        data: [
            { fId: fetch_fId()}
        ],
        columns: [
            // { check: true, required: false, name: 'fId', label: 'ID', align: 'right', field: 'fId', sortable: false },
            {
                check: true, required: true, name: 'MATERIAL_NO', label: '订货号', align: 'left', field: 'MATERIAL_NO', sortable: true, style: {'min-width':'150px'},
                star: true,
                format: (val) => `${val ? val : ''}`, edit: 'text'
            },
            {
                check: true, required: true, name: 'SIEMENS_PRODUCT_OPTION', label: '选件', align: 'left', field: 'SIEMENS_PRODUCT_OPTION', sortable: true, style: {'min-width':'100px'},
                star: true,
                format: (val) => `${val ? val : ''}`, edit: 'text'
            },
            {
                check: true, required: true, name: 'Qty', label: '所需数量', align: 'right', field: 'Qty', sortable: true, class: 'rtl-input', style: {'min-width':'100px'},
                star: true,
                format: (val) => `${val ? val : ''}`, edit: 'text'
            },
            {
                check: true, required: false, name: 'apiStatus', label: '接口返回', align: 'left', field: 'apiStatus', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material', label: '西门子物料编号', align: 'left', field: 'Material', sortable: true,
                format: (val) => `${val ? val : ''}`,
            },
            {
                check: true, required: false, name: 'Supplier', label: '供应商名称（中文）', align: 'left', field: 'Supplier', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Category', label: '产品类型（PTS/PTO）', align: 'center', field: 'Category', sortable: true,
                icon:'fas fa-info-circle',
                icontip:'BANC --> PTO;  \nBANS --> PTO;  \nNORM --> PTS;  \nLEIS --> 虚拟物料 (无实物);  \nZKIT --> 组合物料 (主物料)',
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Enough_Stock', label: '是否满足库存', align: 'left', field: 'Enough_Stock', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'MLFB_Sort', label: '订货号（Sort）', align: 'left', field: 'MLFB_Sort', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Option', label: '选件', align: 'left', field: 'Option', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'DeliveryTime', label: '预计备货时间（日历日)', align: 'left', field: 'DeliveryTime', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'GRT', label: '运输及收货时间（工作日)', align: 'left', field: 'GRT', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ProductListPrice', label: '列表价(不含税)', align: 'right', field: 'ProductListPrice', sortable: true,
                format: (val) => `${val ? tools.formattedPrice(val) : ''}`
            },
            {
                check: true, required: false, name: 'UnitofMeasure', label: '列表价单位', align: 'left', field: 'UnitofMeasure', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_pricing_group', label: '价格组（中国）', align: 'left', field: 'Material_pricing_group', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'PriceGroup', label: '价格组（总部）', align: 'left', field: 'PriceGroup', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'ZDIS_Rate', label: '折扣率', align: 'left', field: 'ZDIS_Rate', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
            {
                check: true, required: false, name: 'Material_Description_ZH', label: '短描述（中文）', align: 'left', field: 'Material_Description_ZH', sortable: true,
                format: (val) => `${val ? val : ''}`
            },
        ],
        tools: {
            show_toolbar: true,
            title: '',
            btns: [
                { index: 1, name: ['查询'], icon: 'fas fa-search', check_lines: 0, on_click: [], sign: 'search', },
                { index: 2, name: ['新增一行'], icon: 'fas fa-plus', check_lines: 0, on_click: [], sign: 'add', },
                { index: 3, name: ['删除行'], icon: 'fas fa-trash', check_lines: 0, on_click: [], sign: 'delete', },
                // { index: 4, name: ['导入数据'], icon: 'fas fa-file-import', check_lines: 0, on_click: [], sign: 'import', },
                { index: 5, name: ['导出数据'], icon: 'fas fa-file-export', check_lines: 0, on_click: [], sign: 'export', },
                {
                    sign: 'refresh',
                    index: 2,
                    name: ['重置页面数据', ''],
                    icon: 'fas fa-redo',
                    click: ['outbound', 'tools_set'],
                },

            ]
        },
        height: ''
    }
}
const dialogData = {
    exportDialogData: {
        loading: false,
        dialog_class: 'default',
        btns: true,
        items: [
            {
                index: 2, label: '格式', showlabel: true, name: 'mode', value: '', type: 'select', filled: false, outlined: true, disable: false,
                rules: [''],
                options: ['XLSX', 'CSV'],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
            {
                index: 2, label: '数据集', showlabel: true, name: 'record', value: '', type: 'select', filled: false, outlined: true, disable: false,
                options: ['全部数据', '搜索结果'],
                rules: [''],
                variant: {
                    origin: ['e2e', 'getFormData', 'update_base_data'], auto_assignment: true,
                },
                minWidth: 160
            },
        ]
    },
    importDialogData: {
        dialog_class: 'default',
        btns: false,
        items: [
            {
                index: 2, label: '格式', showlabel: true, name: 'mode', value: '', type: 'select', filled: false, outlined: true, disable: false,
                options: ['XLSX', 'CSV'],
                minWidth: 160
            },
            {
                index: 2, label: '文件', showlabel: true, name: 'mode', value: '', type: 'upload', filled: false, outlined: true, disable: false,
            }
        ]

    }
}
const creditData = {
    creditDetail: {
        Customer_No: '',
        Customer_Name: '',
        Credit_Limit_Used: '',
        F_Credit: '',
        Credit_Exposure: '',
        Total_Sales_Values: '',
        Currency_Key: '',
        Credit_Control_Area: '',
        Customer_Credit_Limit: '',
        Open_SO_Credit: '',
        Open_Delivery_Credit: '',
        Open_Billing_Credit: '',
        Receivables:'',
    }
}

const state = () => ({
    basedata: Object.assign({}, serialnumberData, packinglistData, outstandingaccountData, largeorderdiscountsData, sprData, bomData, creditData,
        MaterialData, stockData, dialogData)
})
const getters = {
    serialnumberTableSet: state => state.basedata.serialnumberTableSet,
    packinglistTableSet: state => state.basedata.packinglistTableSet,
    outstandingaccountTableSet: state => state.basedata.outstandingaccountTableSet,
    BLART__options: state => state.basedata.BLART__options,
    largeorderdiscountsTableSet: state => state.basedata.largeorderdiscountsTableSet,
    SprSource: state => state.basedata.SprSource,
    BomSource: state => state.basedata.BomSource,
    MaterialSource: state => state.basedata.MaterialSource,
    MaterialInputData: state => state.basedata.MaterialInputData,
    StockSource: state => state.basedata.StockSource,
    getSelected: state => state.basedata.BomSource.selected.length > 0,
    exportDialogData: state => state.basedata.exportDialogData,
    importDialogData: state => state.basedata.importDialogData,
    creditDetail: state => state.basedata.creditDetail,
    BomFrom: state => state.basedata.BomFrom,
    customTable: state => state.basedata.customTable,
}
const mutations = {
    serialChecked(state, data) {
        // let ids = state.basedata.serialnumberTableSet.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.basedata.serialnumberTableSet.selected = state.basedata.serialnumberTableSet.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.basedata.serialnumberTableSet.selected.push(data)
        // }
        state.basedata.serialnumberTableSet.selected = [data]
    },
    packingChecked(state, data) {
        let ids = state.basedata.packinglistTableSet.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.basedata.packinglistTableSet.selected = state.basedata.packinglistTableSet.selected.filter(item => item.id !== data.id)
        } else {
            state.basedata.packinglistTableSet.selected.push(data)
        }
    },
    bomChecked(state, data) {
        let ids = state.basedata.BomSource.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.basedata.BomSource.selected = state.basedata.BomSource.selected.filter(item => item.id !== data.id)
        } else {
            state.basedata.BomSource.selected.push(data)
        }
    },
    zhdlChecked(state, data) {
        // let ids = state.basedata.largeorderdiscountsTableSet.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.basedata.largeorderdiscountsTableSet.selected = state.basedata.largeorderdiscountsTableSet.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.basedata.largeorderdiscountsTableSet.selected.push(data)
        // }
        state.basedata.largeorderdiscountsTableSet.selected = [data]
    },
    openPaymentChecked(state, data){
        // let ids = state.basedata.outstandingaccountTableSet.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.basedata.outstandingaccountTableSet.selected = state.basedata.outstandingaccountTableSet.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.basedata.outstandingaccountTableSet.selected.push(data)
        // }
        state.basedata.outstandingaccountTableSet.selected = [data]
    },
    sprChecked(state, data) {
        // let ids = state.basedata.SprSource.selected.map(item => item.id)
        // if (ids.includes(data.id)) {
        //     state.basedata.SprSource.selected = state.basedata.SprSource.selected.filter(item => item.id !== data.id)
        // } else {
        //     state.basedata.SprSource.selected.push(data)
        // }
        state.basedata.SprSource.selected = [data]
    },
    stockChecked(state, data) {
        let ids = state.basedata.StockSource.selected.map(item => item.fId)
        if (ids.includes(data.fId)) {
            state.basedata.StockSource.selected = state.basedata.StockSource.selected.filter(item => item.fId !== data.fId)
        } else {
            state.basedata.StockSource.selected.push(data)
        }
    },
    materialsChecked(state, data) {
        let ids = state.basedata.MaterialSource.selected.map(item => item.id)
        if (ids.includes(data.id)) {
            state.basedata.MaterialSource.selected = state.basedata.MaterialSource.selected.filter(item => item.id !== data.id)
        } else {
            state.basedata.MaterialSource.selected.push(data)
        }
    },

    clearStockSelected(state) {
        state.basedata.StockSource.selected = []
    },
    clearSerialSelected(state) {
        state.basedata.serialnumberTableSet.selected = []
    },
    clearOutstandingSelected(state) {
        state.basedata.outstandingaccountTableSet.selected = []
    },
    clearLargeOrderSelected(state) {
        state.basedata.largeorderdiscountsTableSet.selected = []
    },
    clearSprSelected(state) {
        state.basedata.SprSource.selected = []
    },
    clearBomSelected(state) {
        state.basedata.BomSource.selected = []
    },


    clearMaterialSelected(state) {
        state.basedata.MaterialSource.selected = []
    },
    clearPackingSelected(state) {
        state.basedata.packinglistTableSet.selected = []
    },


    clearExportDialog(state) {
        state.basedata.exportDialogData.items = state.basedata.exportDialogData.items.map(item => {
            return {
                ...item,
                value: ''
            }
        })
    },
    clearBomCustom(state) {
        state.basedata.BomFrom.body[0].items = state.basedata.BomFrom.body[0].items.map(({ value, ...rest }) => {
            return { ...rest, value: '' }
        })
        state.basedata.customTable.data = [{ fId: 1 }]
    },
    //未清账款列表
    open_payment_list(state, data) {
        state.basedata.outstandingaccountTableSet.data = data.data.data
        state.basedata.outstandingaccountTableSet.pagination.rowsNumber = data.data.count
        if (data.data.BLART__options) {
            state.basedata.BLART__options = data.data.BLART__options
        }
        state.basedata.outstandingaccountTableSet.data.forEach(e => {
            if (e.Is_Due_Date === 'Overdue') {
                e.bgcolor = '#F44336'
                e.color = 'white'
            }
        })
    },
    //信用查询
    credit_detail(state, data) {
        state.basedata.creditDetail = data.data
    },
    //自定义BOM新增一行
    addDrawerTable(state) {
        if (state.basedata.customTable.data.length === 0) {
            last_fId = 0
        }
        state.basedata.customTable.data.push({ fId: fetch_fId() })
    },
    //自定义BOM删除
    deleteDrawerTable(state) {
        const ids = state.basedata.customTable.selected.map(item => item.fId)
        state.basedata.customTable.data = state.basedata.customTable.data.filter(item => !ids.includes(item.fId))
        state.basedata.customTable.selected = []
    },
    //bom列表
    bom_list(state, data) {
        state.basedata.BomSource.pagination.rowsNumber = data.count
        // 先排序,再合并的逻辑
        // const list = tools.mergeTableData(data.data, 'MLFB_Sort')
        // state.basedata.BomSource.data = list


        // 上一行和下一行相同则合并的逻辑
        const rows = data.data
        function merageByMLFB() {
            let MLFB_rowspan = 1; // 初始 MLFB_rowspan 值
            let startIdx = 0; // 用于记录相同数据的起始索引
            // 遍历数组并设置 MLFB_rowspan 属性
            for (let i = 0; i < rows.length; i++) {
              if (i === rows.length - 1 || rows[i].MLFB_Sort !== rows[i + 1].MLFB_Sort) {
                for (let j = startIdx; j <= i; j++) {
                  if (j === startIdx) {
                    rows[j].MLFB_rowspan = MLFB_rowspan;
                  } else {
                    rows[j].MLFB_rowspan = 0;
                  }
                }
                MLFB_rowspan = 1; // 重置 MLFB_rowspan 为 1
                startIdx = i + 1; // 更新起始索引为下一个不同数据的位置
              } else {
                MLFB_rowspan++; // 如果 id 相等，增加 MLFB_rowspan
                rows[i].MLFB_rowspan = 0; // 标记当前对象的 MLFB_rowspan 为 0
              }
            }
            return rows
          }

          merageByMLFB()

          state.basedata.BomSource.data = rows

    },
    //大订单折扣列表
    zhdl_list(state, data) {
        state.basedata.largeorderdiscountsTableSet.data = data.data.data
        state.basedata.largeorderdiscountsTableSet.pagination.rowsNumber = data.data.count
    },
    //特价单查询
    spr_list(state, data) {
        state.basedata.SprSource.pagination.rowsNumber = data.data.count
         state.basedata.SprSource.data = data.data.data

    },
    //库存查询新增
    stockAdd(state) {
        if (state.basedata.StockSource.data.length === 0) {
            last_fId = 0
        }
        const fId = fetch_fId()
        state.basedata.StockSource.data.push({ fId: fId })
        console.log('+++++ fId = ',fId);
    },
    //库存查询删除
    deleteStock(state) {
        const ids = state.basedata.StockSource.selected.map(item => item.fId)
        state.basedata.StockSource.data = state.basedata.StockSource.data.filter(item => !ids.includes(item.fId))
        state.basedata.StockSource.selected = []
    },
    //库存查询结果
    stock_list(state, data) {
        if (data.success) {
            // state.basedata.StockSource.data.forEach((tableItem,tableIndex,tableArray)=>{
            //     const fId = tableArray[tableIndex].fId
            //     data.data.forEach(dataItem => {
            //         if (tableItem.MATERIAL_NO === dataItem.MATERIAL_NO && tableItem.SIEMENS_PRODUCT_OPTION === dataItem.SIEMENS_PRODUCT_OPTION && tableItem.Qty === dataItem.Qty) {
            //             tableArray[tableIndex] = dataItem
            //             tableArray[tableIndex].apiStatus = '1'
            //         }
            //     })
            //     tableArray[tableIndex].fId = fId
            //     console.log('======= index = ',tableIndex,'fId = ',fId);
            // })

            let arr = []
            let native_data = state.basedata.StockSource.data
            let web_data = data.data
            for (let i = 0; i < native_data.length; i++) {
                let n_item = native_data[i];
                const fId = n_item.fId
                // console.log('开始匹配',i,fId);
                for (let j = 0; j < web_data.length; j++) {
                    const w_item = web_data[j];
                    if (n_item.MATERIAL_NO === w_item.MATERIAL_NO
                        && n_item.SIEMENS_PRODUCT_OPTION === w_item.SIEMENS_PRODUCT_OPTION
                        && n_item.Qty === w_item.Qty) {
                        n_item = w_item
                        n_item.apiStatus = '1'
                        // console.log('匹配成功',w_item.MATERIAL_NO,j,w_item.id)
                        // console.log(n_item.MATERIAL_NO,w_item.MATERIAL_NO);
                        break
                    }
                }
                n_item.fId = fId
                // console.log(n_item.fId,'///fId');
                arr.push(JSON.parse(JSON.stringify(n_item)))
            }
            state.basedata.StockSource.data = arr

            // state.basedata.StockSource.data.forEach(e => {
            //     console.log(' - - - - ', e.fId);
            // })
        }
        state.basedata.StockSource.data.forEach(it => {
            if (!it['apiStatus']) {
                it['apiStatus'] = '0'
            }
        })
    },
    //物料主数据
    material_list(state, data) {
        state.basedata.MaterialSource.pagination.rowsNumber = data.data.count
        state.basedata.MaterialSource.data = data.data.data
    },
    //装箱单信息
    packing_list(state, data) {
        state.basedata.packinglistTableSet.pagination.rowsNumber = data.count
        state.basedata.packinglistTableSet.data = data.data
    },
    //序列号
    sn_list(state, data) {
        state.basedata.serialnumberTableSet.pagination.rowsNumber = data.count
        state.basedata.serialnumberTableSet.data = data.data
    },

    ['refreshTableHeight'](state, data) {
        state.basedata.serialnumberTableSet.maxHeight = data + 'px'
        state.basedata.packinglistTableSet.maxHeight = data + 'px'
        state.basedata.outstandingaccountTableSet.maxHeight = data + 'px'
        state.basedata.largeorderdiscountsTableSet.maxHeight = data + 'px'
        state.basedata.SprSource.maxHeight = data + 'px'
        state.basedata.BomSource.maxHeight = data + 'px'
        state.basedata.MaterialSource.maxHeight = data + 'px'
        state.basedata.StockSource.maxHeight = data + 'px'
    }

}
const actions = {
    //未清账款查询 getlist
    async ['fetch_outstanding']({ state, dispatch, rootState }, payload) {
        const message = JSON.parse(JSON.stringify(payload))

        if (message.query.BLART == '') {
            message.query.BLART = {}
        }
        if (state.basedata.outstandingaccountTableSet.loading == true) return
        message.orderby = state.basedata.outstandingaccountTableSet.orderby

        let msg = { command: "open_payment__list", message: message, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.outstandingaccountTableSet.loading = true
    },
    async ['return_open_payment__list']({ state, commit }, payload) {
        state.basedata.outstandingaccountTableSet.loading = false
        if(payload.success){
            commit('open_payment_list', payload)
        }
    },
    //信用查询
    async fetch_credit({ rootState, dispatch }) {

        let msg = { command: "credit_detail__online", message: {}, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
    },
    async return_credit_detail__online({ state, commit }, payload) {
        if(payload.success){
            commit('credit_detail', payload)
        }
    },
    //BOM查询 getlist
    async ['fetch_bom']({ state, dispatch, rootState }, payload) {
        if (state.basedata.BomSource.loading == true) return
        let msg = { command: "bom__list", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.BomSource.loading = true
    },
    async ['return_bom__list']({ state, commit }, payload) {
        state.basedata.BomSource.loading = false
        if(payload.success){
            commit('bom_list', payload.data)
        }
    },
    //自定义BOM新增
    async ['saveCustomBom']({ state, dispatch, rootState }) {
            const form = state.basedata.BomFrom.body[0].items.map(item => {
                return { [item.name]: item.value }
            })
            const table = state.basedata.customTable.data.map(({ colKey, rowKey, fId, ...rest }) => rest)
            let params = table.map(item => {
                return {
                    ...item,
                    ...form[0],
                    ...form[1],
                    ...form[2],
                    ...form[3],
                    ...form[4],
                    ...form[5],
                    ...form[6],
                    ...form[7],
                }
            })

                let msg = { command: "bom__add", message: { data: params }, to: "client" }
                dispatch('channel/socket_send', msg, { root: true })

    },
    async ['return_bom__add']({ state, commit, dispatch }, payload) {
        if (payload.data == true) {
            const query = {}
            const pagination = state.basedata.BomSource.pagination
            dispatch('fetch_bom', { query, pagination })
            dispatch('response/success-tip', { message: '自定义BOM操作成功' }, { root: true })
            commit('clearBomCustom')
        }
    },
    //自定义BOM删除
    async ['deleteCustomBom']({ state, dispatch, rootState }) {
        const ids = state.basedata.BomSource.selected.map(item => item.id)
        let msg = { command: "bom__delete", message: { ids }, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
    },
    async ['return_bom__delete']({ state, commit, dispatch }, payload) {
        if (payload.data == true) {
            const query = {}
            const pagination = state.basedata.BomSource.pagination
            dispatch('fetch_bom', { query, pagination })
            dispatch('response/success-tip', { message: '删除操作成功' }, { root: true })
        }
    },
    //大订单折扣查询 getlist
    async ['fetch_large_order']({ state, dispatch, rootState }, payload) {
        if (state.basedata.largeorderdiscountsTableSet.loading == true) return
        let msg = { command: "zhdl__list", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.largeorderdiscountsTableSet.loading = true
    },
    async ['return_zhdl__list']({ state, commit }, payload) {
        state.basedata.largeorderdiscountsTableSet.loading = false
        if(payload.success){
            commit('zhdl_list', payload)
        }
    },
    //特价单查询 getlist
    async ['fetch_spr_list']({ state, dispatch, rootState }, payload) {
        const message = JSON.parse(JSON.stringify(payload))
        if (message.query.is_used == '') {
            message.query.is_used = {}
        }

        if (state.basedata.SprSource.loading == true) return
        message.orderby = state.basedata.SprSource.orderby
        let msg = { command: "spr__list", message: message, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.SprSource.loading = true
    },
    async ['return_spr__list']({ state, commit }, payload) {
        state.basedata.SprSource.loading = false
        if(payload.success){
            commit('spr_list', payload)
        }
    },
    //库存查询 getlist
    async ['fetch_stock_search']({ state, dispatch, rootState }, payload) {
        if (state.basedata.StockSource.loading == true) return
        let msg = { command: "stock__search", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.StockSource.loading = true
    },
    async ['return_stock__search']({ state, commit }, payload) {
        state.basedata.StockSource.loading = false
        if(payload.success){
            commit('stock_list', payload)
        }
    },
    //物料主数据 getlist
    async ['fetch-client']({ state, dispatch, rootState }, payload) {

        if (state.basedata.MaterialSource.loading == true) return
        payload.orderby = state.basedata.MaterialSource.orderby
        let msg = { command: "material__list", message: payload, to: "client" }
        // timeArr.push({begin:Date.now()})
        dispatch('channel/socket_send', msg, { root: true })

        state.basedata.MaterialSource.loading = true
    },
    async ['return_material__list']({ state, commit }, payload) {
        // timeArr[timeArr.length - 1].end = Date.now()
        // timeArr[timeArr.length - 1].compare = timeArr[timeArr.length - 1].end - timeArr[timeArr.length - 1].begin
        // console.log(timeArr);
        state.basedata.MaterialSource.loading = false
        if(payload.success){
            commit('material_list', payload)
        }
    },
    //装箱单信息 getlist
    async ['fetch_packing']({ state, dispatch, rootState }, payload) {

        if (state.basedata.packinglistTableSet.loading == true) return
        payload.orderby = state.basedata.packinglistTableSet.orderby

        let msg = { command: "packing__list", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.packinglistTableSet.loading = true
    },
    async ['return_packing__list']({ state, commit }, payload) {
        if (payload.success) {
            commit('packing_list', payload.data)
        }
        state.basedata.packinglistTableSet.loading = false
    },
    //序列号 getlist
    async ['fetch_serial_number']({ state, dispatch, rootState }, payload) {

        if (state.basedata.serialnumberTableSet.loading == true) return
        payload.orderby = state.basedata.serialnumberTableSet.orderby
        let msg = { command: "sn__list", message: payload, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        state.basedata.serialnumberTableSet.loading = true
    },
    async ['return_sn__list']({ state, commit }, payload) {
        if (payload.success) {
            commit('sn_list', payload.data)
        }
        state.basedata.serialnumberTableSet.loading = false
    },






    //物料主数据导出
    async ['materialExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })
        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        const {pagination, ...rest}=payload
        obj = {...obj, ...rest}
        let msg = { command: "material__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_material__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },
    //未清账款查询导出
    async ['paymentExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })
        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        // obj.query = payload.query
        const {pagination, ...rest}=payload
        obj = {...obj, ...rest}
        let msg = { command: "open_payment__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_open_payment__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },
    //装箱单导出
    async ['packingExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })
        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        // obj.like_query = payload.like_query
        const {pagination, ...rest}=payload
        obj = {...obj, ...rest}
        let msg = { command: "packing__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_packing__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },
    //特价单导出
    async ['sprExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })

        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        // obj.multi_query = payload.multi_query
        const {pagination, ...rest}=payload
        obj = JSON.parse(JSON.stringify({...obj, ...rest}));
        if (obj.query.is_used == '') {
            obj.query.is_used = {}
        }
        let msg = { command: "spr__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_spr__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },
    //大订单折扣导出
    async ['zhdlExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })

        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        // obj.like_query = payload.like_query
        const {pagination, ...rest}=payload
        obj = {...obj, ...rest}
        let msg = { command: "zhdl__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_zhdl__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },
    //序列号导出
    async ['serialExport']({ state, dispatch, rootState }, payload) {
        const params = state.basedata.exportDialogData.items.map(item => {
            return {
                [item.name]: item.value
            }
        })
        let obj = {}
        obj.file_type = params[0].mode ? params[0].mode.toLowerCase() : 'xlsx',
        obj.search_all = params[1].record ? params[1].record == '全部数据' ? true : false : true
        // obj.like_query = payload.like_query
        const {pagination, ...rest}=payload.formData
        obj = {...obj, ...rest,...payload.params}
        let msg = { command: "sn__export", message: obj, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })
        dispatch('response/info-tip', { message: '正在导出' }, { root: true })
        state.basedata.exportDialogData.loading = true
    },
    async ['return_sn__export']({ state, commit, dispatch }, payload) {
        if (payload.success) {
            window.open(payload.data, '_blank')
            dispatch('response/success-tip', { message: '导出成功' }, { root: true })
        }
        state.basedata.exportDialogData.loading = false
    },






    //手动更新ERP
    async ['material-update']({ state, dispatch, rootState }) {

        const ids = state.basedata.MaterialSource.selected.map(item => item.id)

        let msg = { command: "material__erp", message: { ids }, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })

    },
    async ['return_material__erp']({ state, commit, dispatch }, payload) {
        commit('clearMaterialSelected')
        if (payload.success) {
            dispatch('response/success-tip', { message: '更新成功' }, { root: true })

            let listItemsArray = ['id']
            state.basedata.MaterialSource.columns.forEach(element => {
                listItemsArray.push(element.name)
            });
            let params = {pagination:state.basedata.MaterialSource.pagination,fields: listItemsArray}
            const clean_reg = state.basedata.MaterialInputData.clean_reg
            if (clean_reg) {
                params.clean_reg = {
                    keys: clean_reg.trim(),
                    field: "material__slug"
                }
            }
            dispatch('fetch-client',params)
        }
    },
    // 装箱单信息Update
    async ['packingUpdate']({ state, dispatch, rootState },payload) {

        const ids = state.basedata.packinglistTableSet.selected.map(item => item.id)
        let message = {}
        if(ids.length > 0){
            message = {ids}
        }else{
            message = {...payload}
        }
        let msg = { command: "packing__wms", message, to: "client" }
        dispatch('channel/socket_send', msg, { root: true })

    },
    async ['return_packing__wms']({ state, commit, dispatch }, payload) {
        commit('clearPackingSelected')
        if (payload.success) {
            dispatch('response/success-tip', { message: payload.data }, { root: true })
            const params = {like_query:{},pagination:state.basedata.packinglistTableSet.pagination}
            dispatch('fetch_packing',params)
        }
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
